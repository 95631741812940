import dayjs from "dayjs";
import React, { useEffect } from "react";

// CDN imported in public/index.html

interface Props {
  onChange?: (date: string) => void;
  className?: string;
  name: string;
  defaultDate?: string;
}

const DateRangePicker: React.FC<Props> = ({ className, name, defaultDate, onChange }) => {
  useEffect(() => {
    $(`input[name="date-picker-${name}"]`).daterangepicker(
      {
        singleDatePicker: true,
        autoApply: true,
        startDate: defaultDate || dayjs().format("DD/MM/YYYY"),
        locale: {
          format: "DD/MM/YYYY",
        },
      },
      function (date) {
        onChange && onChange(date.toString());
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, onChange, defaultDate]);
  return (
    <div className="date-range-picker">
      <input type="text" name={`date-picker-${name}`} className={className || ""} />
    </div>
  );
};

export default DateRangePicker;
