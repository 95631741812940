import { Select, Tooltip } from "antd";
import React from "react";
import { areas, contractorList } from "../../data";
import DatePicker from "../../component/commons/DatePicker";
import { formatPriceToVND } from "../../utils";
import dayjs from "dayjs";

const ContractorTablePage: React.FC = () => {
  const data = contractorList;

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div className="contractor-table-page">
      <div className="card mb-5">
        <div className="card-body">
          <div className="d-flex gap-5 flex-wrap mb-5">
            <div className="w-150px">
              <label className="col-form-label col-form-label-sm fw-semibold fs-6x text-end pt-0 pb-1">
                Từ khoá
              </label>
              <input
                type="text"
                className="form-control form-control-sm bg-transparent"
                placeholder="Từ khoá"
              />
            </div>
            <div className="w-125px">
              <label className="col-form-label col-form-label-sm fw-semibold fs-6x text-end pt-0 pb-1">
                Lĩnh vực
              </label>
              <Select
                defaultValue="HH, XL, PTV, TV, HON_HOP"
                className="form-control form-control-sm"
                options={[
                  { value: "HH, XL, PTV, TV, HON_HOP", label: "Tất cả" },
                  { value: "HH", label: "Hàng hoá" },
                  { value: "XL", label: "Xây lắp" },
                  { value: "PTV", label: "Phi tư vấn" },
                  { value: "TV", label: "Tư vấn" },
                  { value: "HON_HOP", label: "Hỗn hợp" },
                ]}
              />
            </div>
            <div className="w-200px">
              <label className="col-form-label col-form-label-sm fw-semibold fs-6x text-end pt-0 pb-1">
                Địa điểm
              </label>
              <Select
                showSearch
                defaultValue="all"
                className="form-control form-control-sm"
                optionFilterProp="children"
                filterOption={filterOption}
                options={[
                  { value: "all", label: "Tất cả" },
                  ...areas.map((item) => {
                    return {
                      value: item.provCode,
                      label: item.provName,
                    };
                  }),
                ]}
              />
            </div>
            <div className="w-150px">
              <label className="col-form-label col-form-label-sm fw-semibold fs-6x text-end pt-0 pb-1">
                Ngày đóng thầu
              </label>
              <DatePicker
                name="closeBidDate"
                className="form-control form-control-sm bg-transparent"
                onChange={(start) => console.log("closeBidDate", start)}
              />
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr className="fw-bold fs-6 text-gray-800">
                  <th
                    className="text-center min-w-125px mw-125px position-sticky bg-white border border-secondary"
                    style={{ left: 0 }}
                  >
                    Mã TBMT
                  </th>
                  <th className="text-center">Tên gói thầu</th>
                  <th className="text-center">Giá thầu</th>
                  <th className="text-center">Lĩnh vực</th>
                  <th className="text-center">Chủ đầu tư</th>
                  <th className="text-center">Địa điểm</th>
                  <th className="text-center">Ngày đăng</th>
                  <th className="text-center">Ngày đóng</th>
                  <th className="text-center">Chi tiết</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => {
                  return (
                    <tr key={item.Id}>
                      <td
                        className="text-truncate text-center min-w-125px mw-125px position-sticky bg-white border border-secondary"
                        style={{ left: 0 }}
                      >
                        {item.notifyNo}
                      </td>
                      <td className="text-truncate min-w-300px mw-300px">
                        <Tooltip placement="topLeft" title={item.bidName}>
                          {item.bidName}
                        </Tooltip>
                      </td>
                      <td className="text-truncate text-center">
                        {formatPriceToVND(item.bidPrice[item.bidPrice.length - 1])}
                      </td>
                      <td className="text-truncate min-w-80px text-center">{item.investField}</td>
                      <td className="text-truncate mw-200px">
                        <Tooltip placement="topLeft" title={item.investorName}>
                          {item.investorName}
                        </Tooltip>
                      </td>
                      <td className="text-truncate">{item.locations[0].provName}</td>
                      <td className="text-truncate">
                        {dayjs(item.publicDate).format("HH:mm - DD/MM/YYYY")}
                      </td>
                      <td className="text-truncate">
                        {dayjs(item.bidCloseDate).format("HH:mm - DD/MM/YYYY")}
                      </td>
                      <td className="text-truncate text-center min-w-70px">link</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContractorTablePage;
